/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import SEOMeta, { SEOMetaType } from '@/components/seo/SEOMeta';
import { PageInfoType, Path } from '@/components/Page';
import DefaultLayout from '@/components/DefaultLayout';
import Navigation, { NavigationData } from '@/components/navigation/Navigation';
import Footer, { FooterData } from '@/components/Footer';
import { getFixedSlugs } from '@/utils/SlugUtils';
import { LoginData } from '@/components/navigation/Navigation';
import LanguageRedirect from '@/components/LanguageRedirect';
import ChatBot from '@/components/chatbot/Chatbot';
import UrgentMessages from '@/components/UrgentMessages';
import { Locales } from '@/types/Locales';
import Main from '@/components/shared/Main';
import Content, {
  ContentBlocksType,
  ContentBlocksProps,
  createContentItems,
} from '@/components/Content';

type Props = ContentBlocksProps & {
  data: {
    contentfulHomepage: {
      seoMetadata: SEOMetaType;
      content: ContentBlocksType;
      node_locale: string;
      headerBackground: string;
    };
    allContentfulHomepage: {
      nodes: Array<PageInfoType>;
    };
    contentfulNavigation: NavigationData;
    contentfulFooter: FooterData;
    contentfulLoginDialog: LoginData;
  };
  location: URL;
};

const Home: React.FC<Props> = (props) => {
  if (!props.data.contentfulHomepage) return null;
  const ContentNavigation = props.data.contentfulNavigation;

  const ContentLogin = props.data.contentfulLoginDialog;
  const { content, node_locale, seoMetadata } = props.data.contentfulHomepage;
  const alternates: Array<Path> = props.data.allContentfulHomepage.nodes.map(
    (node) => {
      return { lang: node.node_locale, url: '/' + node.node_locale };
    },
  );
  const path: Path = {
    lang: node_locale,
    url: '/' + node_locale,
  };
  const contentItems = createContentItems(content, props);
  const backgroundColor: string =
    props.data.contentfulHomepage.headerBackground;

  return (
    <DefaultLayout>
      <Helmet>
        {/* @ts-ignore */}
        <script type='application/ld+json'>
          {`{
            "@context": "http://schema.org/",
            "@type": "Organization",
            "url": "${process.env.GATSBY_SITE_URL}",
            "logo": "${process.env.GATSBY_SITE_URL}/images/logo.svg"
          }`}
        </script>
      </Helmet>
      <SEOMeta alternates={alternates} path={path} seoMetadata={seoMetadata} />
      <LanguageRedirect
        languageSlugs={getFixedSlugs('/')}
        location={props.location}
      />
      <Navigation
        {...ContentNavigation}
        {...ContentLogin}
        languageSlugs={getFixedSlugs('/')}
        path={path}
        pageColor={backgroundColor}
        locale={node_locale}
        location={props.location}
      />
      <Main>
        <UrgentMessages lang={node_locale as Locales} />
        <Content
          pageColor={backgroundColor}
          items={contentItems}
          locale={node_locale}
        />
        <Footer {...props.data.contentfulFooter} />
      </Main>
      <ChatBot locale={node_locale} />
    </DefaultLayout>
  );
};

export default Home;

export const HomepageQuery = graphql`
  query HomepageQuery($locale: String, $articles: [String]) {
    allContentfulHomepage {
      nodes {
        node_locale
      }
    }
    contentfulHomepage(node_locale: { eq: $locale }) {
      seoMetadata {
        ...SeoMetaDataFragment
      }
      node_locale
      content {
        ...ContentFragment
      }
      headerBackground
    }
    contentfulNavigation(node_locale: { eq: $locale }) {
      ...NavigationFragment
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      ...FooterFragment
    }
    relatedArticles: allContentfulArticle(
      filter: { contentful_id: { in: $articles }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...ArticleBlockFragment
      }
    }
    contentfulBlogPageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulSupportHomepageType(node_locale: { eq: $locale }) {
      page {
        node_locale
        ...PagePathFragment
      }
    }
    contentfulLoginDialog(node_locale: { eq: $locale }) {
      ...LoginFragment
    }
  }
`;
