import React from 'react';
import { graphql } from 'gatsby';

import Blocks from '@/components/blocks';
import { ArticlesBlockType } from './blocks/ArticlesBlock';
import { generateSlug } from '@/utils/SlugUtils';
import { mediaQuery } from '@/utils/helpers';
import { PageInfoType } from './Page';
import { ArticleBlockType } from './blog/ArticleBlock';
import { RichTextBlockType } from './blocks/RichTextBlock';
import styled from 'styled-components';
import { RichTextType } from './Richtext';
import { VacancyType } from './blocks/job/Vacancy';
import { SeminarType } from './blocks/seminar/Seminar';
import { JobListingBlockType } from './blocks/job/JobListingBlock';
import { SeminarListingBlockType } from './blocks/seminar/SeminarListingBlock';

type Props = {
  items: any;
  pageColor: string;
  locale?: string;
  topContent?: boolean;
};

export type ContentBlocksProps = {
  data: {
    relatedArticles: {
      nodes: Array<ArticleBlockType>;
    };
    contentfulBlogPageType: {
      page: Array<PageInfoType>;
    };
    contentfulSupportHomepageType: {
      page: Array<PageInfoType>;
    };
    allVacancies: {
      nodes: Array<VacancyType>;
    };
    allSeminars: {
      nodes: Array<SeminarType>;
    };
  };
  pageContext: {
    contentBlocks: ContentBlocksType;
    locale: 'en' | 'fr' | 'nl';
  };
};

const ContentWrapper = styled.div`
  > *:first-child {
    padding-top: 100px;

    ${mediaQuery.lg(`
      padding-top: 160px;
    `)}
  }
`;
export type ContentBlocksType = [
  ArticlesBlockType,
  RichTextBlockType,
  JobListingBlockType,
  SeminarListingBlockType,
];

const Content: React.FC<Props> = (props) => {
  const { items, pageColor, locale, topContent } = props;

  return (
    <ContentWrapper>
      {items?.map((item, index) => {
        if (!item.__typename) return null;
        const block = item.__typename.replace('Contentful', '');

        if (typeof Blocks[block] === 'undefined') return null;

        const Component = Blocks[block];
        const componentKey =
          item.contentful_id || item.id || `${block}_${item.title}`;

        return (
          <Component
            {...item}
            key={componentKey}
            pageColor={pageColor}
            locale={locale}
            index={topContent ? index : 1}
          />
        );
      })}
    </ContentWrapper>
  );
};

export default Content;

export const createContentItems = (
  content: ContentBlocksType,
  props: ContentBlocksProps,
): ContentBlocksType => {
  if (!content) {
    return null;
  }

  let context;

  content.forEach((block) => {
    switch (block.__typename) {
      case 'ContentfulArticlesBlock': {
        const articleBlock: ArticlesBlockType = block as ArticlesBlockType;
        context = props.pageContext.contentBlocks[block.contentful_id];

        if (context) {
          const articleIds = context.articles;
          const blogPath = props.data.contentfulBlogPageType.page
            ? generateSlug(props.data.contentfulBlogPageType.page[0])
            : '#';

          const articles = [];

          if (props.data.relatedArticles && props.data.relatedArticles.nodes) {
            articleIds.forEach((articleId) => {
              const relatedArticle = props.data.relatedArticles.nodes.find(
                (article) => article.contentful_id === articleId,
              );

              if (relatedArticle) {
                relatedArticle.blogPath = blogPath;
                articles.push(relatedArticle);
              }
            });
          }

          articleBlock.articles = articles;
        }

        break;
      }
      case 'ContentfulTopBlock':
      case 'ContentfulJobListingBlock': {
        const vacancies = props.data.allVacancies;
        const jobListingBlock: JobListingBlockType =
          block as JobListingBlockType;
        jobListingBlock.allVacancies = vacancies;
        break;
      }
      case 'ContentfulSeminarListingBlock': {
        const seminars = props.data.allSeminars;
        const seminarListingBlock: SeminarListingBlockType =
          block as SeminarListingBlockType;
        seminarListingBlock.allSeminars = seminars;
        break;
      }
      case 'ContentfulTopBlockSelector':
      case 'ContentfulSingleCenteredBlock':
      case 'ContentfulSingleLargeImageBlock':
      case 'ContentfulRichTextBlock':
      case 'ContentfulLargeCardsBlock':
      case 'ContentfulCTAImageLeftBlock':
      case 'ContentfulImagesSwitchingSidesBlock':
      case 'ContentfulHelpBlock': {
        const blogPath = props.data.contentfulBlogPageType.page
          ? generateSlug(props.data.contentfulBlogPageType.page[0])
          : '#';

        const supportPath = props.data.contentfulSupportHomepageType.page
          ? generateSlug(props.data.contentfulSupportHomepageType.page[0])
          : '#';

        const richTextBlock: RichTextType = block as RichTextType;
        richTextBlock.defaultPaths = {};
        richTextBlock.defaultPaths.blogPath = blogPath;
        richTextBlock.defaultPaths.supportPath = supportPath;

        break;
      }
    }
  });
  return content;
};

export const contentFragmentQuery = graphql`
  fragment ContentFragment on ContentUnion {
    ... on ContentfulTopBlock {
      ...TopBlockFragment
    }
    ... on ContentfulLargeCardsBlock {
      ...LargeCardsBlockFragment
    }
    ... on ContentfulSingleCenteredBlock {
      ...SingleCenteredBlockFragment
    }
    ... on ContentfulSingleLargeImageBlock {
      ...SingleLargeImageBlockFragment
    }
    ... on ContentfulImagesSwitchingSidesBlock {
      ...ImagesSwitchingSidesFragment
    }
    ... on ContentfulSmallCardsBlock {
      ...SmallCardsBlockFragment
    }
    ... on ContentfulTilesBlock {
      ...TilesBlockFragment
    }
    ... on ContentfulTopBlockSelector {
      ...TopBlockSelectorFragment
    }
    ... on ContentfulTabPagesBlock {
      ...TabPagesBlockFragment
    }
    ... on ContentfulArticlesBlock {
      ...ArticlesBlockFragment
    }
    ... on ContentfulHelpBlock {
      ...HelpBlockFragment
    }
    ... on ContentfulStepsBlock {
      ...StepsBlockFragment
    }
    ... on ContentfulMediumCardsBlock {
      ...MediumCardsBlockFragment
    }
    ... on ContentfulPicturesBlock {
      ...PicturesBlockFragment
    }
    ... on ContentfulKeyplanSimulationBlock {
      ...KeyplanSimulationBlockFragment
    }
    ... on ContentfulRichTextBlock {
      ...RichTextBlockFragment
    }
    ... on ContentfulContactBlock {
      ...ContactBlockFragment
    }
    ... on ContentfulIconBenefitsBlock {
      ...IconBenefitsBlockFragment
    }
    ... on ContentfulVisaComparisonTableBlock {
      ...VisaComparisonTableBlockFragment
    }
    ... on ContentfulKeyprivateSimulationBlock {
      ...KeyprivateSimulationBlockFragment
    }
    ... on ContentfulJobListingBlock {
      ...JobListingBlockFragment
    }
    ... on ContentfulSeminarListingBlock {
      ...SeminarListingBlockFragment
    }
    ... on ContentfulReactComponent {
      ...ReactComponentFragment
    }
    ... on ContentfulMediaList {
      ...MediaListFragment
    }
  }
`;
