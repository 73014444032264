export const setToSessionStorage = <T>(key: string, itemToStore: T): void => {
  sessionStorage.setItem(
    key,
    typeof itemToStore !== 'string' ? JSON.stringify(itemToStore) : itemToStore,
  );
};

export const getFromSessionStorage = <T>(
  key: string,
  isJSONParsingNeeded = true,
): T | null => {
  const itemRetrieved = sessionStorage.getItem(key);

  if (!itemRetrieved) {
    return null;
  }

  try {
    const parsedItem = isJSONParsingNeeded
      ? JSON.parse(itemRetrieved)
      : itemRetrieved;

    return parsedItem;
  } catch (error) {
    console.error('Error parsing sessionStorage data:', error);
    return null;
  }
};
