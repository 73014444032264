import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { config } from '@keytrade/functions';
import { UrgentMessage } from '@keytrade/components-urgentMessage';

import RenderOnClientHOC from '@/components/shared/RenderOnClientHOC';
import { mediaQuery } from '@/utils/helpers';
import { Locales } from '@/types/Locales';
import {
  getFromSessionStorage,
  setToSessionStorage,
} from '@/utils/sessionStorage.utils';

const { list: colors } = config.colors;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: 80px 20px 5px;
  margin-bottom: -80px;
  ${mediaQuery.lg(`
    padding: 140px 20px 5px;
    margin-bottom: -140px;
    margin-top: -15px;
  `)}
  background-color: ${colors.BlueLight};
  width: 100%;
`;

const InnerWrapper = styled.div`
  max-width: 1140px;
  margin: auto 0;
  width: 100%;

  > * + * {
    margin-top: 0.8rem;
  }
`;

type Link = {
  id: string;
  type: string;
  text: string;
  url: string;
  slug: string;
};

type UrgentMessage = {
  entryID: string;
  name: string;
  title: string;
  type: 'promo' | 'alert' | 'information';
  bodyText: string;
  startDate: string;
  endDate: string;
  displayOnPublic: boolean;
  displayOnSecure: boolean;
  link?: Link;
};

type Props = {
  lang: Locales;
};

const MESSAGE_STORAGE_KEY = 'dismissed-urgent-message';
const URGENT_MESSAGE_ENDPOINT = `${process.env.GATSBY_API_ORIGIN}/node/backend/cldprxy/chancloud/v1/content/urgentmessage?public=true`;

const UrgentMessages: React.FC<Props> = (props) => {
  const { lang } = props;

  const [urgentMessages, setUrgentMessages] = useState<UrgentMessage[]>([]);
  const dismissed = getFromSessionStorage<string[]>(MESSAGE_STORAGE_KEY) || [];

  const filterDismissedMessages = (messages: UrgentMessage[]) => {
    if (!dismissed?.length) {
      return messages;
    }

    return messages.filter((m) => !dismissed.includes(m.entryID));
  };

  useEffect(() => {
    const fetchUrgentMessages = async () => {
      const url = `${URGENT_MESSAGE_ENDPOINT}&lang=${lang}`;

      try {
        const response = await axios.get(url);
        const { data } = response?.data;

        if (data?.length) {
          setUrgentMessages(filterDismissedMessages(data));
        }
      } catch (error) {}
    };

    fetchUrgentMessages();
  }, [lang]);

  const onClose = (id: string) => {
    setToSessionStorage(MESSAGE_STORAGE_KEY, [...dismissed, id]);
    setUrgentMessages((state) => state.filter((um) => um.entryID !== id));
  };

  if (!urgentMessages.length) {
    return null;
  }

  return (
    <Wrapper>
      <InnerWrapper>
        {urgentMessages.map((urgentMessage) => {
          const { bodyText, startDate, endDate, title, type, link, entryID } =
            urgentMessage;
          let linkUrl = link?.url;
          if (!link?.url && link?.slug) {
            linkUrl = `${process.env.GATSBY_SITE_URL}/${lang}/${link.slug}/`;
          }

          return (
            <UrgentMessage
              key={entryID}
              title={title}
              type={type}
              content={bodyText}
              linkLabel={link?.text}
              linkUrl={linkUrl}
              startdate={startDate}
              enddate={endDate}
              onClose={() => onClose(entryID)}
            />
          );
        })}
      </InnerWrapper>
    </Wrapper>
  );
};

export default RenderOnClientHOC(UrgentMessages);
